import React, {Component} from 'react';
import {Col, Offcanvas, Row} from "react-bootstrap";
import './Header.scss';
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone1.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/person.svg";
import { ReactComponent as BurgerIcon } from "../../assets/icons/burger.svg";
import {Link} from "react-router-dom";

export class Header
    extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showMobileMenu:     false,
            userDropdownHidden: true,
            showModal:          false,
            active: false
        }

        this.toggleMobileMenu   = this.toggleMobileMenu.bind(this);
        this.toggleUserDropdown = this.toggleUserDropdown.bind(this);
        this.toggleModal        = this.toggleModal.bind(this);
        this.handleActive = this.handleActive.bind(this);
    }

    toggleMobileMenu() {
        this.setState({showMobileMenu: !this.state.showMobileMenu});
    }

    toggleUserDropdown() {
        if (this.state.userDropdownHidden) this.setState({userDropdownHidden: false}); else this.setState(
            {userDropdownHidden: true});
    }

    toggleModal() {
        this.setState({showModal: !this.state.showModal});
    }

    toggleUserback() {
        let element = document.getElementsByClassName("userback-button");
        element[0].click();
    }

    handleActive() {        
        this.setState({ active: !this.state.active })
    }

    render() {

        const {user} = this.props;
        const { active } = this.state;

        // const avatar    = (
        //     user && user.avatar_url ? user.avatar_url : "https://via.placeholder.com/80"
        // );
        // const userName  = (
        //     user ? user.first_name + ' ' + user.last_name : <Spinner size={"sm"}
        //                                                              animation={"border"}/>
        // );
        // const userPosition = (user ? user.position.name : <Spinner size={"sm"} animation={"border"}/>);
        // const tasksOpen = (
        //     user.tasks ? user.tasks.length : 0
        // );

        return (
            <>
                <Offcanvas show={this.state.showMobileMenu} onHide={this.toggleMobileMenu}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title> 
                            <a href={"https://makrealty.com/"}>
                                <img src="https://makrealty.com/wp-content/uploads/2022/05/MAK_Logo_Ver5_Dark_CMYK.png"
                                    alt="" className={"logo"}/>
                            </a>
                        </Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <a href={"https://makrealty.com/"} className={`${active} ? 'active : ''`} onClick={this.handleActive}>Home</a>
                        <a href={"https://makrealty.com/list-your-property"}>List Your Property</a>
                        <a href={"https://makrealty.com/concierge"}>Concierge</a>
                        <a href={"https://makrealty.com/blog"}>Blog</a>
                        <a href={"https://makrealty.com/contact"}>Contact</a>
                        <a className={"fw-bold"} href={"https://book.makrealty.com"}>Book Now</a>
                        {/* <div className={"d-block mt-4 text-start"}>
                            <p className={"m-0 d-block"}>Hi, {user ? user.first_name : "guest"}!</p>
                            {user && <Link className={"text-primary"} to={"/logout"}>Logout</Link>}
                            {!user && <><Link className={"text-primary"} to={"/login"}>Login</Link> / <Link
                                className={"text-secondary"} to={"/register"}>Register</Link></>}
                        </div> */}
                    </Offcanvas.Body>
                    <div className='offcanvas-footer'>
                        <div>
                            <a href={"tel:1-(305)-204-6049"}>
                                    <PhoneIcon  />
                            </a>
                            <Link to={"/account"}><PersonIcon /></Link>
                        </div>
                    </div>
                </Offcanvas>
                <Row className={"header container justify-content-between"}>
                    <Col xs={6} md={6} lg={"auto"} className='header-logo-column'>
                        <a href={"https://makrealty.com/"}><img
                            src="https://makrealty.com/wp-content/uploads/2022/05/MAK_Logo_Ver5_Dark_CMYK.png"
                            alt="" className={"logo"}/></a>
                    </Col>
                    <Col lg={"auto"} className={"d-none d-xl-flex justify-content-center links"}>
                        <a className={"me-5"} href={"https://makrealty.com/"}>Home</a>
                        <a className={"me-5"} href={"https://makrealty.com/list-your-property"}>List Your Property</a>
                        <a className={"me-5"} href={"https://makrealty.com/concierge"}>Concierge</a>
                        <a className={"me-5"} href={"https://makrealty.com/blog"}>Blog</a>
                        <a className={"me-5"} href={"https://makrealty.com/contact"}>Contact</a>
                        <a className={"fw-bold"} href={"https://book.makrealty.com"}>Book Now</a>
                    </Col>
                    <Col xs={6} md={6} lg={"auto"} className={"d-flex align-items-center justify-content-end header-menu-column"}>

                       <BurgerIcon onClick={this.toggleMobileMenu} className={"d-xl-none"}/>

                        {
                            user && user.role === 'admin' && 
                                <Link to={"/manager/listings"}>
                                    <PersonIcon />
                                </Link>
                        }

                        <div className={"d-flex align-items-center p-0"}>
                            <a href={"tel:1-(305)-204-6049"} className={"me-2"}>
                                <PhoneIcon className={'d-none d-xl-inline'} />
                            </a>
                            <Link to={"/account"}>
                                <PersonIcon className={'d-none d-xl-inline'} />
                            </Link>
                            <div className={"d-none d-xl-block text-start"}>
                                <p className={"m-0 d-block"}>Hi, {user ? user.first_name : "guest"}!</p>
                                {user && <Link className={"text-primary"} to={"/logout"}>Logout</Link>}
                                {!user && <>
                                    <Link className={"text-primary"} to={"/login"}>Login</Link> / 
                                    <Link className={"text-secondary"} to={"/register"}>Register</Link></>
                                }                               
                            </div>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}