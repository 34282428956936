import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import logo from '../../assets/images/logo-white.png';
import rating from '../../assets/icons/bbbLogo.svg';
import air from '../../assets/icons/air.svg';
import google from '../../assets/icons/google.svg';
import aspca from '../../assets/icons/aspca.svg';
import world from '../../assets/icons/world.svg';
import jewish from '../../assets/icons/jewish.svg'
import cd from '../../assets/icons/cd.svg';
import fc from '../../assets/icons/fc.svg';
import inst from '../../assets/icons/inst.svg'
import bee from '../../assets/icons/bee.svg'
import './Footer.scss';
import GoogleTranslate from "../GoogleTranslate";

export class Footer
    extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showMobileMenu:     false,
            userDropdownHidden: true,
            showModal:          false
        }

    }

    componentDidMount() {
        // if (process.env.NODE_ENV === 'development') {
        //   return;
        // }
        //
        // const script  = document.createElement("script");
        // script.src    = "https://translate.google.com/translate_a/element.js";
        // script.async  = true;
        // script.onload = () => {
        //     setTimeout(function () {
        //         new window.google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
        //     }, 1000)
        // }
        //
        // document.body.appendChild(script);
    }

    render() {
        return (
            <>
                <Container className={"mt-md-5 pt-4 footer d-flex justify-content-between mx-auto"}>
                    <Row className={"mb-5 justify-content-between"}>
                        <div className={"col-12 col-xl-6 mb-5 d-flex flex-column"}>
                            <Col xs={12} sm={12}>
                                <img src={logo} className={"footer-logo"} alt=''/>
                            </Col>
                            <Col
                                xs={12} sm={12}
                                className={"text-right mt-sm-5 mt-4 footer-text align-items-center d-flex"}>
                                <p className={"m-0"}>MAK Realty is the premier short-term vacation rental agency In the
                                    Miami area. We
                                    represent vacation home and condo owners, Airbnb owners, and full-service hotels in
                                    one
                                    of the top cultural and entertainment centers in the world, South Florida! Through
                                    our
                                    talented staff and economies of scale, we’ve created an innovative and rare
                                    experience:
                                    personalized service, high-end accommodations, and affordable prices. Our commitment
                                    to
                                    quality and satisfaction is evident in every detail of your experience with MAK
                                    Realty.</p>
                            </Col>
                            <Col xs={12} className={"mt-sm-5 mt-4 left-icons"}>
                                <img alt='' src={rating} className={"rating"}/>
                                <img alt='' src={air} className={"air"}/>
                                <img alt='' src={google} className={"google"}/>
                            </Col>
                            <Col xs={12} className={"mt-sm-5 mt-4"}>
                                <GoogleTranslate/>
                            </Col>
                        </div>
                        <div className='mb-sm-5 col-xl-12 d-flex flex-column mt-sm-4 footer-list-block'>
                            <Col xs={12} sm={12} md={6} lg={6} className='d-flex footer-list'>
                                <div>
                                    <h4 className={"text-white title"}>Menu</h4>
                                    <ul className={"list-unstyled"}>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://makrealty.com/"}>Home</a>
                                        </li>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://book.makrealty.com"}>Book Now</a>
                                        </li>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://makrealty.com/list-your-property"}>List Your Property</a>
                                        </li>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://makrealty.com/list-your-property"}>Concierge</a>
                                        </li>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://makrealty.com/list-your-property"}>Blog</a>
                                        </li>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://makrealty.com/contact"}>Contact</a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h4 className={"text-white title"}>Support</h4>
                                    <ul className={"list-unstyled"}>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://makrealty.com/login"}>Sign In</a>
                                        </li>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://makrealty.com/account"}>Account</a>
                                        </li>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://makrealty.com/blog"}>Blog</a>
                                        </li>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://makrealty.com/privacy-policy"}>Privacy Policy</a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h4 className={"text-white title"}>Follow</h4>
                                    <ul className={"list-unstyled"}>
                                        <li className='h'>
                                            <a rel="noopener" href={"https://www.facebook.com/makrealtygroup"}>Facebook</a>
                                        </li>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://www.instagram.com/makproperties/"}>Instagram</a>
                                        </li>
                                        <li className='home'>
                                            <a rel="noopener" href={"https://www.airbnb.com/users/show/45832356"}>AirBnB</a>
                                        </li>
                                        <li className='home'>
                                            <a rel="noopener" href={""} className=''>VRBO</a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} className='d-flex flex-column mt-sm-5 mt-3'>
                                <p className='charities'>Charities We Support</p>
                                <div className='charities-images'>
                                    <img alt='' src={aspca} className={"aspca"}/>
                                    <img alt='' src={world} className={"aspca"}/>
                                    <img alt='' src={jewish} className={"aspca"}/>
                                </div>
                            </Col>
                        </div>
                    </Row>
                </Container>

                <div className={"footer-copyright"}>
                    <Container>
                        <Row className='d-flex justify-content-end flex-sm-row flex-column'>
                            <Col className='d-flex footer-social-icons'>
                                <a rel="noopener" href="https://www.facebook.com/makrealtygroup" target='_blank' > <img alt='' src={fc}
                                                                                                        className={"fc"}/></a>
                                <a rel="noopener" href="https://www.instagram.com/makproperties/" target='_blank' > <img alt='' src={inst}
                                                                                                         className={"inst"}/></a>
                                <a rel="noopener" href="https://www.airbnb.com/users/show/45832356" target='_blank' > <img alt='' src={cd}
                                                                                                           className={"cd"}/></a>
                            </Col>
                            <Col className='footer-copyright-text'>
                                © 2023 All rights reserved. &nbsp; <a rel="noopener" href={"https://beetechy.com"}
                                                                      target={"_blank"} > Designed by
                                Bee Techy <img alt='' src={bee} className={"bee"}/></a>.
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }

}