import React, { Component } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { Link, withRouter } from 'react-router-dom';
import './NewTableCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const FONTAINEBLEAUAirBnbIds = ['65945f74b273840010e5b720', '65905db152cfea0011326616', '65dc2cbaf21f99001383f4a3'];

class ListingCard extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);

    this.state = {
      index: 0,
    };
  }

  handleIndexChange(newIndex) {
    this.setState({ index: newIndex });
  }

  handleClick(e) {
    this.props.onClick(e, this.props.data);
  }

  handleRedirect(e) {
    const { data, checkIn, checkOut, adults, history, children } = this.props;
    const basePath = `/listing/${data.id}/${FONTAINEBLEAUAirBnbIds.includes(data.airbnb_id) ? 'fontainebleau/' : ''}`;
    const query = `?checkIn=${checkIn}&checkOut=${checkOut}&adults=${adults}&children=${children}`;
    history.push(basePath + query);
  }

  render() {
    const { data, user, rating } = this.props;

    var subTotal = data.price_array
      ? data.price_array.subtotal.toLocaleString('en-Us', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : 0;

    return (
      <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
        <Card className={'tableCard'} onClick={this.handleClick} id={data.id}>
          <div className="tableCardHeader">
            <Carousel activeIndex={this.state.index} onSelect={this.handleIndexChange} interval={null}>
              <Carousel.Item>
                <div
                  className="tableCardHeader"
                  style={{ background: "url('" + (data.files.length ? data.files[0].url : '') + "')" }}
                ></div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="tableCardHeader"
                  style={{ background: "url('" + (data.files.length ? data.files[1].url : '') + "')" }}
                ></div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="tableCardHeader"
                  style={{ background: "url('" + (data.files.length ? data.files[2].url : '') + "')" }}
                ></div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="tableCardHeader"
                  style={{ background: "url('" + (data.files.length ? data.files[3].url : '') + "')" }}
                ></div>
              </Carousel.Item>
            </Carousel>
          </div>

          <div className={'tableCardBody'}>
            <div className="tableCardBodyTitleWrapper">
              <h1 className={'mb-3 tableCardBodyTitle'}>
                {data.name}{' '}
                {user && (user.email === 'eddy@beetechy.com' || user.email === 'mk@makrealty.com') ? <small>{data.nickname}</small> : <></>}
              </h1>
              <div className="tableCardBodyRating">
                <FontAwesomeIcon icon={faStar} />
                <span>{Number.parseFloat(rating).toFixed(1)}</span>
              </div>
            </div>
            <Row className={'tableCardMeta'}>
              <Col xs={4} className={'amenity-list'}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.5 7.5H16.6667C17.1269 7.5 17.5 7.1269 17.5 6.66667V3.33333C17.5 2.8731 17.1269 2.5 16.6667 2.5H3.33333C2.8731 2.5 2.5 2.8731 2.5 3.33333V16.6667C2.5 17.1269 2.8731 17.5 3.33333 17.5H6.66667C7.1269 17.5 7.5 17.1269 7.5 16.6667V2.5"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M5.83301 14.9999H7.49966" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M5.83301 11.6667H7.49966" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M5.83301 8.33341H7.49966" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M5.83301 4.99992H7.49966" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.6667 5.83325V7.49992" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14.9997 5.83325V7.49992" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9.58301 17.4999H10.833" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12.917 17.4999H14.167" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M17.5 16.25V16.6667C17.5 17.1269 17.1269 17.5 16.6667 17.5H16.25"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M17.4997 9.58325V10.8333" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.4997 12.9167V14.1667" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                {data.square_feet ? data.square_feet.toLocaleString() : 'N/A'} SF
              </Col>
              <Col xs={4} className={'amenity-list'}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.667 10.0557H3.33366C2.41283 10.0557 1.66699 10.8015 1.66699 11.7223V14.1665C1.66699 14.6265 2.04033 14.9998 2.50033 14.9998H17.5003C17.9603 14.9998 18.3337 14.6265 18.3337 14.1665V11.7223C18.3337 10.8015 17.5878 10.0557 16.667 10.0557Z"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.91699 10.1083V10.0558V10.0558C2.91699 8.64417 4.06116 7.5 5.47283 7.5H7.41699C8.61366 7.5 9.58366 8.47 9.58366 9.66667V10.0558"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.58398 10.0558V10.0558C9.58398 8.64417 10.7282 7.5 12.1398 7.5H14.529C15.9407 7.5 17.0848 8.64417 17.0848 10.0558V10.0558V10.1083"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.33398 8.65825V5.83325C3.33398 4.45242 4.45315 3.33325 5.83398 3.33325H14.1673C15.5482 3.33325 16.6673 4.45242 16.6673 5.83325V8.65825"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M17.5007 15V16.6667" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M2.50065 15V16.6667" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                {data.beds} Bed
              </Col>
              <Col xs={4} className={'amenity-list'}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.106 7.6001H9.56183C8.99266 7.6001 8.59099 7.0426 8.77099 6.50343V6.50343C9.05016 5.6651 9.83433 5.1001 10.7177 5.1001H10.9493C11.8327 5.1001 12.6168 5.6651 12.896 6.50343V6.50343C13.076 7.0426 12.6743 7.6001 12.106 7.6001V7.6001Z"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.82772 11.0525H3.33355C2.82189 11.0525 2.43105 11.5092 2.51022 12.0142L2.79689 13.8475C3.05022 15.4708 4.44772 16.6667 6.09022 16.6667H13.9102C15.5527 16.6667 16.9502 15.4708 17.2036 13.8483L17.4902 12.015C17.5694 11.51 17.1786 11.0533 16.6669 11.0533H10.2194"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.833 5.1V5C10.833 3.61917 11.9522 2.5 13.333 2.5V2.5C14.7138 2.5 15.833 3.61917 15.833 5V11.0525"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.46467 14.3858H7.58717C6.61884 14.3858 5.83301 13.6008 5.83301 12.6317V10.9608C5.83301 10.43 6.26301 10 6.79384 10H9.25801C9.78884 10 10.2188 10.43 10.2188 10.9608V12.6317C10.2188 13.6008 9.43384 14.3858 8.46467 14.3858Z"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M5.03366 16.5L4.16699 17.5" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M15.8335 17.5L14.9668 16.5" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                {Math.floor(data.bathrooms)} Bath
              </Col>
              <Col xs={4} className={'amenity-list'}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.49707 8.91263L9.11237 3.24226C9.62325 2.80434 10.3771 2.80434 10.888 3.24226L17.5033 8.91263"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.432 8.00963V3.17893C16.432 2.99802 16.3601 2.82453 16.2322 2.69661C16.1043 2.56869 15.9308 2.49683 15.7499 2.49683H13.7036C13.3269 2.49683 13.0215 2.80221 13.0215 3.17893V5.08624"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9541 16.6696V12.3496C7.9541 11.9729 8.2595 11.6675 8.63621 11.6675H10.0004"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M3.56918 8.00977V16.6695" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M10.0002 16.6695H2.49707" stroke="#676767" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.3872 11.4691C14.5025 11.2352 14.7406 11.0872 15.0013 11.0872C15.262 11.0872 15.5001 11.2352 15.6154 11.4691L16.1159 12.4837C16.2152 12.6859 16.408 12.8259 16.631 12.8577L17.752 13.0245C18.0097 13.0623 18.2236 13.2431 18.3039 13.4908C18.3842 13.7386 18.317 14.0105 18.1305 14.1923L17.3203 14.9824C17.1585 15.1393 17.0851 15.3663 17.1241 15.5882L17.3148 16.7028C17.3592 16.9596 17.2538 17.2193 17.043 17.3724C16.8322 17.5256 16.5526 17.5457 16.3221 17.4242L15.3212 16.898C15.1207 16.791 14.8801 16.791 14.6796 16.898L13.6796 17.4242C13.4491 17.5457 13.1695 17.5256 12.9587 17.3724C12.7479 17.2193 12.6425 16.9596 12.6869 16.7028L12.8776 15.5882C12.9162 15.3663 12.8428 15.1396 12.6815 14.9824L11.8749 14.1923C11.6884 14.0106 11.6211 13.7389 11.7012 13.4912C11.7813 13.2435 11.995 13.0626 12.2525 13.0245L13.3726 12.8577C13.5956 12.8259 13.7884 12.6859 13.8877 12.4838L14.3872 11.4691Z"
                    stroke="#676767"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                With balcony
              </Col>
            </Row>

            <Row className='row'>
              <Col xs={6} className="price-wrapper">
                <div className='price-wrapper2'>
                  <div className={'price lh-1 fs-4 fw-500'}>${subTotal}</div>
                  <div className={'price-subtitle'}>taxes not incl.</div>
                </div>
                <div className="price-total">
                  ·<span> ${data.price_array.total} total</span>
                </div>
              </Col>
              <Col xs={6} className="price-wrapper-button">
                {/* <Link
                  variant={'transparent'}
                  size={'md'}
                  className={'btn rounded-circle me-3 btn-transparent'}
                  to={
                    '/listing/' + data.id + '?checkIn=' + checkIn + '&checkOut=' + checkOut + '&adults=' + adults + '&children' + children
                  }
                ></Link> */}
                <Link onClick={this.handleRedirect} className={'btn view-more-btn'}>
                  VIEW MORE
                </Link>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    );
  }
}

export default withRouter(ListingCard);
