import React from 'react';
import success from '../../../assets/images/success.svg';
import moment from 'moment';

export default function SuccessfulCheckout({ data }) {
  const urlParams = new URLSearchParams(window.location.search);
  const adults = urlParams.get('adults');
  const children = urlParams.get('children');
  const nights = moment(urlParams.get('checkOut')).diff(urlParams.get('checkIn'), 'days');
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  return (
    <div className="result-page-wrapper">
      <div className="result-page">
        <div className="result-message">
          <img src={success} alt=''/>
          <p className="result-top-text">Thank you for your reservation. We wish you a pleasant holiday!</p>
          <p className="result-bot-text">
            {' '}
            A letter with details of the reservation and instructions on how to manage the reservation has been sent to your email. Or you
            can manage your reservation through your account.
          </p>
        </div>
        <div className="reservation-details">
          <div>
            <h1 className="reservation-details-table-header">reservation details</h1>
            <table>
              <tbody>
                <tr>
                  <td>Traveler full name</td>
                  <td>{`${data.firstName} ${data.lastName}`}</td>
                </tr>
                <tr>
                  <td>Your reservation </td>
                  <td>{nights + (nights > 1 ? ' nights' : ' night')} </td>
                </tr>
                <tr>
                  <td>You have booked for</td>
                  <td>
                    {adults} adult{adults > 1 ? 's' : ''} {children > 0 ? `, ${children} child${children > 1 ? 'ren' : ''}` : ''}
                  </td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{`${data.location}`}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h1 className="reservation-details-table-header">apartments Contacts</h1>
            <table>
              <tr>
                <td>Phone</td>
                <td>{`${data.phone}`}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{`${data.email}`}</td>
              </tr>
            </table>
          </div>
          <div>
            <h1 className="reservation-details-table-header">Pricing</h1>
            <table>
              <tr>
                <td>Price</td>
                <td>{formatter.format(data.totalPrice)}</td>
              </tr>
              <tr>
                <td>Cancellation fee</td>
                <td>
                  <ul>
                    <li>Until Jun 25, 2024 11:59 pm: 100% refund</li>
                    <li>From Jun 26, 2024 12 am: 50% refund</li>
                    <li>No refund is given on the day of check in or after.</li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
