import React, { useEffect, useState } from 'react';
import { ReactComponent as PlanIcon } from '../../../../assets/icons/grid-ruler.svg';
import { ReactComponent as RoomsIcon } from '../../../../assets/icons/Group.svg';
import { ReactComponent as BathsIcon } from '../../../../assets/icons/Group1.svg';
import Api from '../../../../Api';

const StartTourItem = ({ item }) => {

  const [startTour, setStartTour] = useState(null);
  const [error, setError] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const checkIn = queryParams.get('checkIn');
  const checkOut = queryParams.get('checkOut');
  const adults = queryParams.get('adults');
  const children = queryParams.get('children');

  useEffect(() => {
    if (item.id) {
      const url = `${process.env.REACT_APP_API_URL}/api/listings/${item.id}?checkIn=${checkIn}&checkOut=${checkOut}&adults=${adults}&children=${children}&include=pricing,amenities`;
      Api.get(url)
        .then((data) => setStartTour(data.data))
        .catch((error) => setError(error));
    }
  }, [item.id, checkIn, checkOut, adults, children]);

  const handleReserve = () => {
    localStorage.setItem("sort", "desc");
    window.open(`/checkout/${startTour.id}?checkIn=${checkIn}&checkOut=${checkOut}&adults=${adults}&children=${children}`, '_blank');
  };
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);

    const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(date).toUpperCase();
    return formattedDate;
  };

  return (
    !error && (
      <div className="fb-startTour-items-item">
        <div className="fb-startTour-items-item-header">
          <div className="fb-startTour-items-item-header-title">
            {' '}
            FONTAINEBLEAU TRESOR <br></br>{' '}
            <span>
              <b>{item.side}</b> PENTHOUSE
            </span>{' '}
            <br></br> <span>IN MIAMI BEACH</span>
          </div>
          <div className="fb-startTour-items-item-header-priceDiv">
            <div className="fb-startTour-items-item-header-priceDiv-price">
              <div className="fb-startTour-items-item-header-priceDiv-price-text1">
                {`$${startTour?.pricing?.subtotal}`} <span>*</span>
              </div>
              <div className="fb-startTour-items-item-header-priceDiv-price-text2">taxes not incl.</div>
              <div className="fb-startTour-items-item-header-priceDiv-price-text2 mt-3">
                * Average price per night. To change the dates, proceed to checkout.
              </div>
            </div>
            <div className="fb-startTour-items-item-header-priceDiv-flexCol mt-3">
              <button className="fb-startTour-items-item-header-priceDiv-flexCol-button" onClick={handleReserve}>
                RESERVE
              </button>
              <div className="fb-startTour-items-item-header-priceDiv-price-text3">{`${formatDate(checkIn)} - ${formatDate(
                checkOut,
              )}`}</div>
            </div>
          </div>
        </div>
        <div className="fb-startTour-items-item-body">
          <iframe title={`Tour for listing ${item.id}`} className="fb-startTour-items-item-body-image" src={item.iframeSrc} />
          <div className="fb-startTour-items-item-body-drag">
            DRAG <br />& MOVE
          </div>
        </div>
        <div className="fb-startTour-items-item-footer">
          <div className="fb-startTour-items-item-footer-item">
            <PlanIcon className="fb-startTour-items-item-footer-item-icon" />
            <div className="fb-startTour-items-item-footer-item-text">{`${startTour?.square_feet || item.sqft} SqFT`}</div>
          </div>
          <div className="fb-startTour-items-item-footer-item">
            <PlanIcon className="fb-startTour-items-item-footer-item-icon" />
            <div className=" fb-startTour-items-item-footer-item-text">{`${startTour?.square_feet || item.sqm} SqM`}</div>
          </div>
          <div className="fb-startTour-items-item-footer-item">
            <RoomsIcon className="fb-startTour-items-item-footer-item-icon" />
            <div className=" fb-startTour-items-item-footer-item-text">{`${startTour?.bedrooms} BD`}</div>
          </div>
          <div className="fb-startTour-items-item-footer-item">
            <BathsIcon className="fb-startTour-items-item-footer-item-icon" />
            <div className=" fb-startTour-items-item-footer-item-text">{`${startTour?.bathrooms} BA`}</div>
          </div>
        </div>
      </div>
    )
  );
};

export default StartTourItem;
